
.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &&::-webkit-scrollbar {
        display: none;
    }
}
.ant-skeleton-input {
  background-image: linear-gradient(
    90deg,
    rgba(255,255,255,0.3) 25%,
    rgba(255,255,255,0.2) 37%,
    rgba(255,255,255,0.1) 63%
  ) !important;
}
.site-form-item-icon {
  color:rgba(0,0,0,0.5);
}

/*
    Animations
*/
@keyframes blink {
    0% {opacity: 1;}
    40% {opacity: 1;}
    60% {opacity: 0;}
    100% {opacity: 0;}
}

.input-cursor { 
    border: 1px solid rgba(0,0,0,0.5);
    width: 2px;
    height:100%;
    margin-left: 3px;
    animation: blink .6s linear infinite alternate;
}

.bounce {
    animation: bounce 2s;
    animation-iteration-count: 1;
}
.bounce-sm {
    animation: bounce-sm 1s;
    animation-iteration-count: 1;
}
.reveal{
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
}
.reveal.active{
    transform: translateX(0);
    opacity: 1;
}
.reveal-top{
    transform: translateY(-150px);
    opacity: 0;
    transition: 1s all ease;
}
.reveal-top.active {
    transform: translateX(0);
    opacity: 1;
}
.reveal-right{
    transform: translateX(150px);
    opacity: 0;
    transition: 1s all ease;
}
.reveal-right.active{
    transform: translateX(0);
    opacity: 1;
}
.reveal-left{
    transform: translateX(-150px);
    opacity: 0;
    transition: 1s all ease;
}
.reveal-left.active{
    transform: translateX(0);
    opacity: 1;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
}
@keyframes bounce-sm {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-4px);
    }
    60% {
      transform: translateY(-2px);
    }
}
@keyframes inAnimation {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  
  @keyframes outAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  

@media only screen and (max-width: 1000px) {
    
}